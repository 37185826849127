// Generated by Framer (20caf11)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {qTzEF09kU: {hover: true}};

const serializationHash = "framer-sW3JD"

const variantClassNames = {qTzEF09kU: "framer-v-1f8za94"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, lineColor, text, width, ...props}) => { return {...props, EHwggOTIz: text ?? props.EHwggOTIz ?? "Text", nKqPNlRNt: lineColor ?? props.nKqPNlRNt ?? "rgb(255, 255, 255)"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string;lineColor?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, EHwggOTIz, nKqPNlRNt, Y1jKtkJjZZ5xwzpHRd, HmWXS9X01Z5xwzpHRd, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "qTzEF09kU", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1f8za94", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"qTzEF09kU"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"qTzEF09kU-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-vyn00q"} data-framer-name={"Text"} layoutDependency={layoutDependency} layoutId={"BjB6T4yfl"}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW5zdHJ1bWVudCBTYW5zLTYwMA==", "--framer-font-family": "\"Instrument Sans\", \"Instrument Sans Placeholder\", sans-serif", "--framer-font-size": "calc(var(--variable-reference-HmWXS9X01-Z5xwzpHRd) * 1px)", "--framer-font-weight": "600", "--framer-letter-spacing": "0px", "--framer-line-height": "20px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-Y1jKtkJjZ-Z5xwzpHRd))", "--framer-text-transform": "uppercase"}}>Text</motion.p></React.Fragment>} className={"framer-1unj8kn"} data-framer-name={"Link"} fonts={["GF;Instrument Sans-600"]} layoutDependency={layoutDependency} layoutId={"ddAqKJkLK"} style={{"--extracted-r6o4lv": "var(--variable-reference-Y1jKtkJjZ-Z5xwzpHRd)", "--variable-reference-HmWXS9X01-Z5xwzpHRd": HmWXS9X01Z5xwzpHRd, "--variable-reference-Y1jKtkJjZ-Z5xwzpHRd": Y1jKtkJjZZ5xwzpHRd}} text={EHwggOTIz} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-hx947"} layoutDependency={layoutDependency} layoutId={"o0vMaNh7v"} style={{backgroundColor: nKqPNlRNt}}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-sW3JD.framer-1hvege4, .framer-sW3JD .framer-1hvege4 { display: block; }", ".framer-sW3JD.framer-1f8za94 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 23px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-sW3JD .framer-vyn00q { align-content: flex-start; align-items: flex-start; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 100%; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-sW3JD .framer-1unj8kn { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-sW3JD .framer-hx947 { bottom: 0px; flex: none; height: 2px; left: -1px; overflow: hidden; position: absolute; width: 1px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-sW3JD.framer-1f8za94, .framer-sW3JD .framer-vyn00q { gap: 0px; } .framer-sW3JD.framer-1f8za94 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-sW3JD.framer-1f8za94 > :first-child { margin-left: 0px; } .framer-sW3JD.framer-1f8za94 > :last-child { margin-right: 0px; } .framer-sW3JD .framer-vyn00q > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-sW3JD .framer-vyn00q > :first-child { margin-top: 0px; } .framer-sW3JD .framer-vyn00q > :last-child { margin-bottom: 0px; } }", ".framer-sW3JD.framer-v-1f8za94.hover .framer-hx947 { left: 0px; width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 23
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"seA2NAWZs":{"layout":["auto","fixed"]}}}
 * @framerVariables {"EHwggOTIz":"text","nKqPNlRNt":"lineColor"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerfWyeEYGTV: React.ComponentType<Props> = withCSS(Component, css, "framer-sW3JD") as typeof Component;
export default FramerfWyeEYGTV;

FramerfWyeEYGTV.displayName = "Underline Animation";

FramerfWyeEYGTV.defaultProps = {height: 23, width: 48};

addPropertyControls(FramerfWyeEYGTV, {EHwggOTIz: {defaultValue: "Text", displayTextArea: false, title: "Text", type: ControlType.String}, nKqPNlRNt: {defaultValue: "rgb(255, 255, 255)", title: "Line Color", type: ControlType.Color}} as any)

addFonts(FramerfWyeEYGTV, [{explicitInter: true, fonts: [{family: "Instrument Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/instrumentsans/v1/pximypc9vsFDm051Uf6KVwgkfoSxQ0GsQv8ToedPibnr-yp2JGEJOH9npSQb_gfwmS0v3_7Y.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})